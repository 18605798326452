import { ICityOffer, ICompanyOffer, IJobOfferCard, IJobOfferResponse } from '@/models/jobBank/interfaces/jobOffer'

export const formatJobOfferCard = (apiResponse: IJobOfferResponse[]): IJobOfferCard[] => {
  return apiResponse.map(({ name, publishDate, endDate, _id, company, city } : {
        name: string,
        publishDate: Date | null,
        endDate: Date | null,
        _id: string,
        company: ICompanyOffer[],
        city: ICityOffer[]}) => {
    let dateFormated = null
    if (endDate != null) {
      endDate = new Date(endDate)
      dateFormated = endDate.toLocaleDateString('es-AR')
    }
    const state = wichState(publishDate, endDate)
    const companyInfo = {
      company: company[0]?.name ? company[0]?.name : '',
      logo: company[0]?.logo ? company[0]?.logo : ''
    }
    const locationInfo = {
      city: city[0]?.name ? city[0]?.name : '',
      country: city[0]?.countryName ? city[0]?.countryName : ''
    }
    const eachJobOffer = { _id, offer: name, location: locationInfo, state: state, dueDate: dateFormated, postulant: undefined, ...companyInfo }
    return eachJobOffer
  })
}

export const formatJobOfferList = (apiResponse: IJobOfferResponse[]): any => {
  return apiResponse.map(({ name, publishDate, endDate, _id, modality, city, company, hierarchy, link, quantityEmployees } : {
        name: string,
        publishDate: Date | null,
        endDate: Date | null,
        _id: string,
        city: ICityOffer[],
        modality: any,
        company: any,
        hierarchy: any,
        link: string,
        quantityEmployees: number
    }) => {
    let dateFormated = null
    if (endDate != null) {
      endDate = new Date(endDate)
      dateFormated = endDate.toLocaleDateString('es-AR')
    }
    const state = wichState(publishDate, endDate)
    const modalityValue = modality[0]?.value ? modality[0]?.value : ''
    const locationInfo = city[0]?.name ? city[0]?.name + ', ' + city[0]?.countryName : ''
    const area = company[0]?.sector ? company[0]?.sector : ''
    const hierarchyValue = hierarchy[0]?.value ? hierarchy[0]?.value : ''

    const eachJobOffer = { name, _id, modality: modalityValue, location: locationInfo, state: state, dueDate: dateFormated, area: area, hierarchy: hierarchyValue, link, quantityEmployees }
    return eachJobOffer
  })
}

const wichState = (publishDate: any, endDate: any) => {
  let state
  const today = new Date()
  if (endDate != null) { endDate = new Date(endDate) }
  if (publishDate != null) { publishDate = new Date(publishDate) }
  switch (true) {
    case publishDate == null:
      state = 'No publicada'
      break
    case endDate <= today:
      state = 'Finalizada'
      break
    case publishDate <= today:
      state = 'Publicada'
      break
    default:
      state = 'No publicada'
      break
  }
  return state
}
