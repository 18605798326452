import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-3f4abbc1")
const _hoisted_1 = { class: "cardBorder" }
const _hoisted_2 = { class: "jobOfferCard" }
const _hoisted_3 = {
  key: 0,
  class: "leftCard"
}
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = { class: "centerCard" }
const _hoisted_6 = { class: "clickable" }
const _hoisted_7 = { class: "rightCard" }
const _hoisted_8 = { class: "postulant" }
const _hoisted_9 = { class: "card-edit" }
const _hoisted_10 = { class: "edit" }
const _hoisted_11 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showImage)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.jobOfferCard.logo || require('@/assets/images/noImage.svg'),
              alt: _ctx.jobOfferCard.logo || 'Noimage'
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_link, {
          to: `/job-bank/job-offers/view/${_ctx.jobOfferCard._id}`
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.jobOfferCard.offer ||  'Nombre de la oferta laboral'), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("h5", null, _toDisplayString(_ctx.jobOfferCard.company ||  'Empresa') + " " + _toDisplayString(_ctx.jobOfferCard.location.city ? ' - ' + _ctx.jobOfferCard.location.city + ', ' : '') + " " + _toDisplayString(_ctx.jobOfferCard.location.city ? _ctx.jobOfferCard.location.country : ''), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.whenEndDate(_ctx.jobOfferCard.dueDate,_ctx.jobOfferCard.state)), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, [
          _createElementVNode("span", null, _toDisplayString(_ctx.jobOfferCard.postulant || 3) + " " + _toDisplayString(_ctx.jobOfferCard.postulant == 1 ? 'postulante' : 'postulantes'), 1)
        ]),
        _createElementVNode("p", null, "Estado: " + _toDisplayString(_ctx.jobOfferCard.state || 'No publicada'), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_Button, {
        class: "publishButton",
        label: _ctx.jobOfferCard.state === 'Publicada' ? 'No publicar' : 'Publicar',
        onClick: _ctx.handleStatusChange
      }, null, 8, ["label", "onClick"]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("img", {
          src: require('@/assets/icons/common/edit.svg'),
          alt: "editar"
        }, null, 8, _hoisted_11),
        _createVNode(_component_Button, {
          label: "Editar",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(`/job-bank/job-offers/view/${_ctx.jobOfferCard._id}/edit/offer-info`))),
          class: "p-button-link"
        })
      ])
    ])
  ]))
}