
import { defineComponent, PropType } from 'vue'
import { IJobOfferCard } from '@/models/jobBank/interfaces/jobOffer'
import { JobBankRouteNames } from '@/router/route-names'
import Button from 'primevue/button'
import { useRouter } from 'vue-router'

export default defineComponent({
  emits: ['handleStatusChange'],
  props: {
    jobOfferCard: {
      required: true,
      type: Object as PropType<IJobOfferCard>
    },
    showImage: {
      required: false,
      type: Boolean as PropType<boolean>
    }
  },
  setup (props, { emit }) {
    const editRoute = 'JobBankRouteNames.EDIT_JOBBANK'
    const router = useRouter()

    const handleStatusChange = () => {
      if (props.jobOfferCard.state === 'Publicada') {
        emit('handleStatusChange', { card_id: props.jobOfferCard._id })
      } else {
        router.push(`/job-bank/job-offers/view/${props.jobOfferCard._id}/edit/publish`)
      }
    }

    const whenEndDate = (dueDate: any, state: string) => {
      let date
      switch (true) {
        case dueDate == null:
          date = ''
          break
        case state === 'Finalizada':
          date = `Finalizó el ${dueDate}`
          break
        case state === 'Publicada':
          date = `Finaliza el ${dueDate}`
          break
        default:
          date = ''
          break
      }
      return date
    }

    return {
      JobBankRouteNames,
      editRoute,
      Button,
      handleStatusChange,
      whenEndDate
    }
  }
})
