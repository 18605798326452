
import { defineComponent, onMounted, Ref, ref, computed } from 'vue'
import { JobBankRouteNames } from '@/router/route-names'
import { CompaniesAPI } from '@/api/companies.api'
import { JobOffersAPI } from '@/api/job-offers.api'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'
import jobOfferCard from '@/views/jobBank/children/components/JobOfferCard.vue'
import { IJobOfferCard } from '@/models/jobBank/interfaces/jobOffer'
import { formatJobOfferCard } from '../helpers/formatJobOfferCard'

export default defineComponent({
  components: {
    jobOfferCard
  },
  setup () {
    const route = useRoute()
    const { jobBankId } = route.params
    const showImage = ref(false)
    const formObject = ref({})
    const jobOffersObject = ref({})
    const loading = ref(false)
    const jobOffers: Ref<IJobOfferCard[]> = ref([])
    const jobOffersRaw : Ref<any[]> = ref([])
    const unPublishCard = ref()
    const toast = useToast()
    const currentRoute = computed(() => {
      return route.name
    })
    const getCompany = async () => {
      formObject.value = await CompaniesAPI.findCompany(jobBankId as string)
    }
    const getJobOffers = async () => {
      loading.value = true
      try {
        const { data: apiResponse } = await JobOffersAPI.findJobOffersByCompanyId(jobBankId as string)
        jobOffersRaw.value = apiResponse
        jobOffers.value.push(...formatJobOfferCard(apiResponse))
        loading.value = false
      } catch (e) {
        loading.value = false
        jobOffers.value = []
      }
    }
    const displayModal = ref(false)

    const openModal = () => {
      displayModal.value = true
    }

    const closeModal = () => {
      displayModal.value = false
    }
    const handleStatusChange = (event: any) => {
      openModal()
      const cardId = event?.card_id
      unPublishCard.value = jobOffersRaw.value.find(jobOfferCard => jobOfferCard._id === cardId)
    }
    const unPublish = () => {
      unPublishCard.value.publishDate = null
      unPublishCard.value.endDate = null
      JobOffersAPI.updateJobOffer(unPublishCard.value, unPublishCard.value._id)
      closeModal()
      toast.add({ severity: 'success', detail: '¡Listo! Se dejó de publicar la oferta laboral.', life: 3500 })
      jobOffers.value = []
      getJobOffers()
    }

    onMounted(async () => {
      await getCompany()
      await getJobOffers()
    })

    return {
      JobBankRouteNames,
      formObject,
      jobOffersObject,
      jobOffers,
      showImage,
      currentRoute,
      handleStatusChange,
      openModal,
      closeModal,
      displayModal,
      unPublish
    }
  }

})
